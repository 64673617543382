
import {
  computed, defineComponent, reactive, onBeforeMount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarModeloDocumentoFiscal from '@/components/Compartilhados/SelecionarModeloDocumentoFiscal.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarFormaPagamento from '@/components/Cadastros/Financeiro/SelecionarFormaPagamento.vue';
import SelecionarProdutoMovimento from '@/components/Cadastros/Produtos/SelecionarProdutoMovimento.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import { IRecebimentoMercadoria } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useFocusBase } from '@/core/composables/FocusBase';
import { IDTOUnidadeProdutoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOUnidadeProdutoMovimento';
import { IPessoa } from '@/models/Entidades/Cadastros/Pessoas/IPessoa';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import { IPessoaJuridica } from '@/models/Entidades/Cadastros/Pessoas/IPessoaJuridica';
import { IPessoaFisica } from '@/models/Entidades/Cadastros/Pessoas/IPessoaFisica';
import { IPessoaInscricoes } from '@/models/Entidades/Cadastros/Pessoas/IPessoaInscricoes';
import { ETipoInscricaoEstadual } from '@/models/Enumeradores/Compartilhados/ETipoInscricaoEstadual';
import { IPessoaFiscal } from '@/models/Entidades/Cadastros/Pessoas/IPessoaFiscal';
import { IPessoaEndereco } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEndereco';
import { IPessoaRelacao } from '@/models/Entidades/Cadastros/Pessoas/IPessoaRelacao';
import { IPessoaEmpresa } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEmpresa';
import { IPessoaPlanoContaCategoria } from '@/models/Entidades/Cadastros/Pessoas/IPessoaPlanoContaCategoria';
import { IPessoaContato } from '@/models/Entidades/Cadastros/Pessoas/IPessoaContato';
import { IPessoaAnexo } from '@/models/Entidades/Cadastros/Pessoas/IPessoaAnexo';
import { IColaborador } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IColaborador';
import { IClienteFinanceiro } from '@/models/Entidades/Cadastros/Pessoas/Clientes/IClienteFinanceiro';
import { ICliente } from '@/models/Entidades/Cadastros/Pessoas/Clientes/ICliente';
import { ITransportadora } from '@/models/Entidades/Cadastros/Pessoas/Transportadoras/ITransportadora';
import { IFornecedor } from '@/models/Entidades/Cadastros/Pessoas/Fornecedores/IFornecedor';
import { IRepresentante } from '@/models/Entidades/Cadastros/Pessoas/Representantes/IRepresentante';
import { ETipoEndereco } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoEndereco';
import { IEndereco } from '@/models/Entidades/Cadastros/Localizacoes/IEndereco';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import {
  IProduto, IProdutoCombustivel, IProdutoDefinicao, IProdutoMedicamento,
} from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Cadastros/Produtos/ETipoRastreabilidade';
import { ETipoLancamentoRastreabilidade } from '@/models/Enumeradores/Cadastros/Produtos/ETipoLancamentoRastreabilidade';
import { ETipoCodigo } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCodigo';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';
import { IFormaPagamento, IFormaPagamentoTipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import SelecionarEstoqueProdutoMovimento from '@/components/Cadastros/Estoques/SelecionarEstoqueProdutoMovimento.vue';
import RecebimentoMercadoriasBuscaFinanceiro from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasBuscaFinanceiro.vue';
import { INotaFiscalDetalhePagamento, INotaFiscalDetalhePagamentoAnexo, INotaFiscalDetalhePagamentoCentroCusto } from '@/models/Entidades/Fiscal/INotaFiscal';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';

export default defineComponent({
  name: 'RecebimentoMercadoriasRevisarInformacoes',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    recebimentoMercadoria: {
      type: Object as () => IRecebimentoMercadoria,
      required: true,
    },
    apresentarAcoes: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    CampoNumerico,
    SelecionarData,
    SelecionarModeloDocumentoFiscal,
    SelecionarPessoa,
    SelecionarFormaPagamento,
    SelecionarProdutoMovimento,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    SelecionarEstoqueProdutoMovimento,
    RecebimentoMercadoriasBuscaFinanceiro,
  },
  emits: ['update:recebimentoMercadoria', 'update:apresentarAcoes'],
  setup(props, { emit }) {
    let debounce = 0;
    let debounceBuscaFinanceiro = 0;

    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoCidade = new ServicoCidade();

    const servicoFormaPagamento = new ServicoFormaPagamento();
    servicoFormaPagamento.requisicaoSistema();

    const servicoPessoa = new ServicoPessoa();
    servicoPessoa.requisicaoSistema();

    const computedRecebimentoMercadoria = computed({
      get: () => props.recebimentoMercadoria,
      set: (val: IRecebimentoMercadoria) => {
        emit('update:recebimentoMercadoria', val);
      },
    });

    const computedApresentarAcoes = computed({
      get: () => props.apresentarAcoes,
      set: (val: boolean) => {
        emit('update:apresentarAcoes', val);
      },
    });

    const state = reactive({
      dadosUnidadesSelecionadas: [] as IDTOUnidadeProdutoMovimento [],
      formaPagamento: {} as IFormaPagamento,
      fornecedorPreCadastro: {} as IPessoa,
      transportadoraPreCadastro: {} as IPessoa,
      produtosPreCadastro: [] as IProduto[],
      carregandoDados: true,
      chaveItens: 0,
      indexDetalhesEstoques: -1,
      apresentarEstoquesItem: false,
      inputFocusEstoqueItem: false,
      popOverHoverEstoqueItem: false,
      carregando: false,
      apresentarItens: false,
      apresentarBuscaFinanceiro: false,
      codigosTiposDocumentosFinanceiros: [] as number[],
      buscaDadosAtualizados: false,
    });
    const {
      focusBase, focusElemento, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-revisao-itens-recebimento-mercadoria';

    async function atualizarFormaPagamento() {
      state.codigosTiposDocumentosFinanceiros = [];
      if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.codigoFormaPagamento)) {
        state.formaPagamento = await servicoFormaPagamento.obter(computedRecebimentoMercadoria.value.codigoFormaPagamento);
        state.formaPagamento.tiposDocumentosFinanceiro = await servicoFormaPagamento.obterTiposDocumentos(computedRecebimentoMercadoria.value.codigoFormaPagamento, computedRecebimentoMercadoria.value.codigoEmpresa);
        if (UtilitarioGeral.validaLista(state.formaPagamento.tiposDocumentosFinanceiro)) {
          state.codigosTiposDocumentosFinanceiros = state.formaPagamento.tiposDocumentosFinanceiro.map((c) => c.codigoTipoDocumentoFinanceiro);
        }
        let definicaoTipoDocumentoFinanceiro:IFormaPagamentoTipoDocumentoFinanceiro = {} as IFormaPagamentoTipoDocumentoFinanceiro;
        let aplicarUnicoTipoDocumento = false;
        if (UtilitarioGeral.validaLista(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento)) {
          if (state.formaPagamento.tiposDocumentosFinanceiro.length === 1) {
            definicaoTipoDocumentoFinanceiro = state.formaPagamento.tiposDocumentosFinanceiro[0];
            aplicarUnicoTipoDocumento = true;
          }
          for (let index = 0; index < computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.length; index += 1) {
            if (!UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTituloFinanceiro)) {
              if (!aplicarUnicoTipoDocumento && UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro)) {
                const definicaoTipoDocumentoFinanceiroExiste = state.formaPagamento.tiposDocumentosFinanceiro.find((c) => c.codigoTipoDocumentoFinanceiro === computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro);
                if (definicaoTipoDocumentoFinanceiroExiste !== undefined) {
                  definicaoTipoDocumentoFinanceiro = definicaoTipoDocumentoFinanceiroExiste;
                }
              }
              if (aplicarUnicoTipoDocumento || UtilitarioGeral.validaCodigo(definicaoTipoDocumentoFinanceiro.codigoTipoDocumentoFinanceiro)) {
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro = definicaoTipoDocumentoFinanceiro.codigoTipoDocumentoFinanceiro;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoConta = definicaoTipoDocumentoFinanceiro.codigoConta;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria = definicaoTipoDocumentoFinanceiro.codigoPlanoContaCategoriaPadrao;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].gerarCarteira = definicaoTipoDocumentoFinanceiro.gerarCarteira;
              } else {
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro = 0;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoConta = 0;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria = 0;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].gerarCarteira = false;
              }
            }
          }
        }
      } else {
        state.formaPagamento = {} as IFormaPagamento;
      }
    }

    function verificaElementoEsquerda(emDigitacao:boolean, evento:any) {
      if (!emDigitacao) {
        elementoAnterior(evento);
      }
    }

    function verificaElementoDireita(emDigitacao:boolean, evento:any) {
      if (!emDigitacao) {
        proximoElemento(evento);
      }
    }
    function verificaElementoAcima(emDigitacao:boolean, evento:any, dataAttributeColuna:string) {
      if (!emDigitacao) {
        elementoAcima(evento, dataAttributeColuna);
      }
    }

    function verificaElementoAbaixo(emDigitacao:boolean, evento:any, dataAttributeColuna:string) {
      if (!emDigitacao) {
        elementoAbaixo(evento, dataAttributeColuna);
      }
    }

    function instanciaPessoa(): IPessoa {
      const pessoa:IPessoa = {} as IPessoa;
      pessoa.codigo = 0;
      pessoa.ativo = true;
      pessoa.tipoPessoa = ETipoPessoa.Fisica;
      pessoa.pessoaJuridica = {} as IPessoaJuridica;
      pessoa.pessoaFisica = {} as IPessoaFisica;
      pessoa.pessoaFisica.estadoCivil = 1;
      pessoa.pessoaFisica.categoriaCnh = 1;
      pessoa.inscricoes = {} as IPessoaInscricoes;
      pessoa.inscricoes.tipoIe = ETipoInscricaoEstadual.NaoContribuinte;
      pessoa.fiscal = {} as IPessoaFiscal;
      pessoa.enderecos = [] as IPessoaEndereco[];
      pessoa.contatos = [] as IPessoaContato[];
      pessoa.anexos = [] as IPessoaAnexo[];
      pessoa.categoriasPlanoContas = [] as IPessoaPlanoContaCategoria[];
      pessoa.empresas = [] as IPessoaEmpresa[];
      pessoa.empresas.push({ codigo: 0, codigoPessoa: 0, codigoEmpresa: props.empresa });
      pessoa.relacoes = [] as IPessoaRelacao[];
      pessoa.colaborador = {} as IColaborador;
      pessoa.colaborador.funcoes = [];
      pessoa.colaborador.departamentos = [];
      pessoa.cliente = {} as ICliente;
      pessoa.cliente.financeiro = {} as IClienteFinanceiro;
      pessoa.cliente.financeiro.negativacaoSpc = false;
      pessoa.cliente.financeiro.bloqueado = false;
      pessoa.cliente.financeiro.protestarTitulos = 0;
      pessoa.transportadora = {} as ITransportadora;
      pessoa.fornecedor = {} as IFornecedor;
      pessoa.representante = {} as IRepresentante;
      pessoa.fiscal.tipoAtividade = 4;
      return pessoa;
    }

    function instanciaProduto():IProduto {
      const produto:IProduto = {} as IProduto;
      produto.codigo = 0;
      produto.descricao = '';
      produto.descricaoCompleta = '';
      produto.codigoUnidade = 0;
      produto.tipoProduto = 0;
      produto.fatorUnidadePrincipal = 1;
      produto.codigoCest = '';
      produto.genero = '';
      produto.possuiVariacao = false;
      produto.combustivel = false;
      produto.combustivelDetalhe = {} as IProdutoCombustivel;
      produto.medicamento = false;
      produto.medicamentoDetalhe = {} as IProdutoMedicamento;
      produto.dataCadastro = '';
      produto.dataAlteracao = '';
      produto.ativo = true;
      produto.empresas = [];
      produto.empresas.push({ codigo: 0, codigoProduto: 0, codigoEmpresa: props.empresa });
      produto.categorias = [];
      produto.definicoes = [];
      const definicaoPadrao: IProdutoDefinicao = {} as IProdutoDefinicao;
      definicaoPadrao.tipoRastreabilidade = ETipoRastreabilidade.Nenhum;
      definicaoPadrao.tipoLancamentoRastreabilidade = ETipoLancamentoRastreabilidade.Manual;
      definicaoPadrao.codigo = 0;
      definicaoPadrao.ativo = true;
      definicaoPadrao.codigoInterno = '';
      definicaoPadrao.bloquearNovasCompras = false;
      definicaoPadrao.custos = [];
      definicaoPadrao.precos = [];
      definicaoPadrao.codigos = [];
      definicaoPadrao.unidades = [];
      definicaoPadrao.fornecedores = [];
      definicaoPadrao.imagens = [];
      definicaoPadrao.caracteristicasVariacao = [];
      produto.definicoes.push(definicaoPadrao);
      produto.caracteristicas = [];
      produto.produtosSemelhantes = [];

      return produto;
    }

    async function obterCodigoCidade(nomeCidade: string, uf:string): Promise<number> {
      let codigoCidade = 0;

      const cidade = await servicoCidade.obterCidadePorNomeUf(nomeCidade, uf);
      if (UtilitarioGeral.objetoValido(cidade)) {
        if (UtilitarioGeral.validaCodigo(cidade.codigo)) {
          codigoCidade = cidade.codigo;
        }
      }

      return codigoCidade;
    }

    function preenchePreCadastroProdutos() {
      if (UtilitarioGeral.validaLista(computedRecebimentoMercadoria.value.itens)) {
        state.produtosPreCadastro = [];
        for (let index = 0; index < computedRecebimentoMercadoria.value.itens.length; index += 1) {
          const produto = instanciaProduto();
          produto.descricao = computedRecebimentoMercadoria.value.itens[index].descricaoProdutoNfFornecedor;
          produto.codigoUnidade = computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.codigoUnidade;

          if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.codigoFornecedor)) {
            produto.definicoes[0].fornecedores.push({
              codigo: 0, codigoProdutoDefinicao: 0, codigoFornecedor: computedRecebimentoMercadoria.value.codigoFornecedor, identificador: computedRecebimentoMercadoria.value.itens[index].codigoProdutoNfFornecedor, principal: true,
            });
          }

          if (UtilitarioGeral.valorValido(computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.codigoBarras) && computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.codigoBarras !== 'SEM GTIN') {
            produto.definicoes[0].codigos.push({
              codigo: 0, codigoProdutoDefinicao: 0, tipoCodigo: ETipoCodigo.CodigoBarras, codigoProduto: computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.codigoBarras, principal: true,
            });
          }
          produto.origemMercadoria = computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.impostos.origemMercadoria;
          produto.codigoNcm = computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.impostos.codigoNcm;
          if (UtilitarioGeral.valorValido(computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.impostos.st.cest)) {
            produto.codigoCest = computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.impostos.st.cest;
          }
          state.produtosPreCadastro.push(produto);
        }
      }
    }

    async function preenchePreCadastros() {
      state.fornecedorPreCadastro = instanciaPessoa();
      state.fornecedorPreCadastro.relacoes.push({ codigo: 0, codigoPessoa: 0, tipoRelacao: ETipoRelacaoPessoa.Fornecedor } as IPessoaRelacao);

      if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.emitente.codigoPessoa)) {
        computedRecebimentoMercadoria.value.codigoFornecedor = await servicoPessoa.obterCodigoRelacao(computedRecebimentoMercadoria.value.notaFiscal.emitente.codigoPessoa, ETipoRelacaoPessoa.Fornecedor);
      } else {
        if (computedRecebimentoMercadoria.value.notaFiscal.emitente.cpfCnpj.length === 11) {
          state.fornecedorPreCadastro.tipoPessoa = ETipoPessoa.Fisica;
          state.fornecedorPreCadastro.pessoaFisica.cpf = computedRecebimentoMercadoria.value.notaFiscal.emitente.cpfCnpj;
          state.fornecedorPreCadastro.pessoaFisica.nomeCompleto = computedRecebimentoMercadoria.value.notaFiscal.emitente.razaoSocialNome;
          const enderecoFornecedor: IPessoaEndereco = {} as IPessoaEndereco;
          enderecoFornecedor.codigo = new Date().getTime();
          enderecoFornecedor.codigoPessoa = 0;
          enderecoFornecedor.codigoEndereco = 0;
          enderecoFornecedor.tipoEndereco = ETipoEndereco.Principal;
          enderecoFornecedor.identificacao = 'Principal';
          enderecoFornecedor.endereco = {} as IEndereco;
          enderecoFornecedor.endereco.codigo = 0;
          enderecoFornecedor.endereco.cep = computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.cep;
          enderecoFornecedor.endereco.logradouro = computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.endereco;
          enderecoFornecedor.endereco.complemento = computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.complemento;
          enderecoFornecedor.endereco.bairro = computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.bairro;
          enderecoFornecedor.endereco.numero = computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.numero;
          enderecoFornecedor.endereco.codigoCidade = await obterCodigoCidade(computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.nomeCidade, computedRecebimentoMercadoria.value.notaFiscal.emitente.endereco.uf);
          state.fornecedorPreCadastro.enderecos.push(enderecoFornecedor);
        } else {
          state.fornecedorPreCadastro.tipoPessoa = ETipoPessoa.Juridica;
          state.fornecedorPreCadastro.pessoaJuridica.cnpj = computedRecebimentoMercadoria.value.notaFiscal.emitente.cpfCnpj;
        }
        state.fornecedorPreCadastro.email = computedRecebimentoMercadoria.value.notaFiscal.emitente.email;
      }

      state.transportadoraPreCadastro = instanciaPessoa();
      state.transportadoraPreCadastro.relacoes.push({ codigo: 0, codigoPessoa: 0, tipoRelacao: ETipoRelacaoPessoa.Transportadora } as IPessoaRelacao);

      if (UtilitarioGeral.objetoValido(computedRecebimentoMercadoria.value.notaFiscal.transporte) && UtilitarioGeral.valorValido(computedRecebimentoMercadoria.value.notaFiscal.transporte.cpfCnpj)) {
        if (computedRecebimentoMercadoria.value.notaFiscal.transporte.cpfCnpj.length === 11) {
          state.transportadoraPreCadastro.tipoPessoa = ETipoPessoa.Fisica;
          state.transportadoraPreCadastro.pessoaFisica.cpf = computedRecebimentoMercadoria.value.notaFiscal.transporte.cpfCnpj;
          state.transportadoraPreCadastro.pessoaFisica.nomeCompleto = computedRecebimentoMercadoria.value.notaFiscal.transporte.razaoSocialNome;
          const enderecoTransportadora: IPessoaEndereco = {} as IPessoaEndereco;
          enderecoTransportadora.codigo = new Date().getTime();
          enderecoTransportadora.codigoPessoa = 0;
          enderecoTransportadora.codigoEndereco = 0;
          enderecoTransportadora.tipoEndereco = ETipoEndereco.Principal;
          enderecoTransportadora.identificacao = 'Principal';
          enderecoTransportadora.endereco = {} as IEndereco;
          enderecoTransportadora.endereco.codigo = 0;
          enderecoTransportadora.endereco.logradouro = computedRecebimentoMercadoria.value.notaFiscal.transporte.enderecoCompletoTransportadora;
          state.transportadoraPreCadastro.enderecos.push(enderecoTransportadora);
        } else {
          state.transportadoraPreCadastro.tipoPessoa = ETipoPessoa.Juridica;
          state.transportadoraPreCadastro.pessoaJuridica.cnpj = computedRecebimentoMercadoria.value.notaFiscal.transporte.cpfCnpj;
        }
      }

      preenchePreCadastroProdutos();
      state.chaveItens += 1;
    }

    function quantidadeFoco(index: number) {
      state.indexDetalhesEstoques = index;
      state.apresentarEstoquesItem = true;
    }

    function quantidadeBlur() {
      if (!state.popOverHoverEstoqueItem && !state.inputFocusEstoqueItem) {
        state.indexDetalhesEstoques = -1;
        state.apresentarEstoquesItem = false;
      }
    }

    function salvarItemGrade(index: number) {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.valorTotal = servicoCalculosComerciais.calculaValorTotal(computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.quantidade, computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.precoUnitario, computedRecebimentoMercadoria.value.itens[index].itemNotaFiscal.valorDesconto, 0);
      }, 600);
    }

    function apresentarBuscarVincularTitulos() {
      state.apresentarBuscaFinanceiro = !state.apresentarBuscaFinanceiro;

      if (state.apresentarBuscaFinanceiro) {
        clearTimeout(debounceBuscaFinanceiro);
        debounceBuscaFinanceiro = setTimeout(() => {
          const element = document.getElementById('resultadoBuscaTitulosRecebimentoFinanceiro');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 600);
      }
    }

    onBeforeMount(async () => {
      state.carregandoDados = true;
      state.apresentarItens = false;
      if (UtilitarioGeral.validaLista(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento)) {
        const formaPagamento = await servicoFormaPagamento.obterFormaPagamentoAtivaPorEmpresaMovimentoEMeioPagamento(props.empresa, ETipoMovimentoComercial.Compras, computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[0].codigoTipoDocumentoFinanceiro);
        if (UtilitarioGeral.objetoValido(formaPagamento)) {
          if (UtilitarioGeral.validaCodigo(formaPagamento.codigo)) {
            computedRecebimentoMercadoria.value.codigoFormaPagamento = formaPagamento.codigo;
            await atualizarFormaPagamento();
            for (let index = 0; index < computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.length; index += 1) {
              const tipoDocumentoFormaPagamento = state.formaPagamento.tiposDocumentosFinanceiro.find((c) => c.meioPagamentoTipoDocumentoFinanceiro === computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro);
              if (tipoDocumentoFormaPagamento !== undefined) {
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro = tipoDocumentoFormaPagamento.codigoTipoDocumentoFinanceiro;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].descricaoTipoDocumentoFinanceiro = tipoDocumentoFormaPagamento.descricaoTipoDocumentoFinanceiro;
                if (UtilitarioGeral.validaCodigo(tipoDocumentoFormaPagamento.codigoConta)) {
                  computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoConta = tipoDocumentoFormaPagamento.codigoConta;
                  computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].descricaoConta = tipoDocumentoFormaPagamento.descricaoConta;
                }
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria = tipoDocumentoFormaPagamento.codigoPlanoContaCategoriaPadrao;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].nomePlanoContaCategoria = tipoDocumentoFormaPagamento.nomePlanoContaCategoriaPadrao;

                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].gerarCaixa = tipoDocumentoFormaPagamento.gerarCaixaDiario;
                computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].gerarCarteira = tipoDocumentoFormaPagamento.gerarCarteira;
              }
            }
          }
        }
      }

      await preenchePreCadastros();
      state.carregandoDados = false;
      state.apresentarItens = true;
      computedApresentarAcoes.value = true;
    });

    async function estrategiaAlteracaoPessoa() {
      if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.emitente.codigoPessoa)) {
        computedRecebimentoMercadoria.value.codigoFornecedor = await servicoPessoa.obterCodigoRelacao(computedRecebimentoMercadoria.value.notaFiscal.emitente.codigoPessoa, ETipoRelacaoPessoa.Fornecedor);
      } else {
        computedRecebimentoMercadoria.value.codigoFornecedor = 0;
      }
      await preenchePreCadastroProdutos();
    }

    function adicionarPagamento() {
      const detalhePagamento = {} as INotaFiscalDetalhePagamento;
      detalhePagamento.codigo = 0;
      detalhePagamento.codigoNotaFiscal = 0;
      detalhePagamento.codigoConta = 0;
      detalhePagamento.codigoTipoDocumentoFinanceiro = 0;
      detalhePagamento.codigoTituloFinanceiro = 0;
      detalhePagamento.statusTituloFinanceiro = EStatusTituloFinanceiro.Pendente;
      detalhePagamento.alterado = false;
      detalhePagamento.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
      detalhePagamento.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
      computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.push(detalhePagamento);
    }

    function excluirPagamento(index:number) {
      computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.splice(index, 1);
    }

    function removerTodosPagamentos() {
      computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento = [];
    }

    async function verificaPreenchimentoDefinicaoTipoDocumento(index: number) {
      if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro)) {
        const definicaoTipoDocumentoFinanceiro = state.formaPagamento.tiposDocumentosFinanceiro.find((c) => c.codigoTipoDocumentoFinanceiro === computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoTipoDocumentoFinanceiro);
        if (definicaoTipoDocumentoFinanceiro !== undefined) {
          if (UtilitarioGeral.validaCodigo(definicaoTipoDocumentoFinanceiro.codigoConta)) {
            computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoConta = definicaoTipoDocumentoFinanceiro.codigoConta;
            computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].descricaoConta = definicaoTipoDocumentoFinanceiro.descricaoConta;
          }

          if (UtilitarioGeral.validaCodigo(definicaoTipoDocumentoFinanceiro.codigoPlanoContaCategoriaPadrao)) {
            computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria = definicaoTipoDocumentoFinanceiro.codigoPlanoContaCategoriaPadrao;
            computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].nomePlanoContaCategoria = definicaoTipoDocumentoFinanceiro.nomePlanoContaCategoriaPadrao;
          }

          if (UtilitarioGeral.validaBoleano(definicaoTipoDocumentoFinanceiro.gerarCarteira)) {
            computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].gerarCarteira = definicaoTipoDocumentoFinanceiro.gerarCarteira;
          }

          const pagamentosSemPreencherTipoDocumento = computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.some((c) => c.codigoTipoDocumentoFinanceiro === undefined || c.codigoTipoDocumentoFinanceiro === 0);
          if (pagamentosSemPreencherTipoDocumento) {
            Modal.confirm({
              title: 'Confirmação!',
              content: `Deseja aplicar o tipo de documento:${definicaoTipoDocumentoFinanceiro.descricaoTipoDocumentoFinanceiro} para os demais pagamentos não preenchidos?`,
              okText: 'Sim',
              okType: 'primary',
              cancelText: 'Não',
              autoFocusButton: null,
              centered: true,
              onOk: async () => {
                for (let indexLinha = 0; indexLinha < computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.length; indexLinha += 1) {
                  if (indexLinha !== index) {
                    if (!UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoTituloFinanceiro)
                    && !UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoTipoDocumentoFinanceiro)) {
                      computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoTipoDocumentoFinanceiro = definicaoTipoDocumentoFinanceiro.codigoTipoDocumentoFinanceiro;

                      if (UtilitarioGeral.validaCodigo(definicaoTipoDocumentoFinanceiro.codigoConta)) {
                        computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoConta = definicaoTipoDocumentoFinanceiro.codigoConta;
                        computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].descricaoConta = definicaoTipoDocumentoFinanceiro.descricaoConta;
                      }
                      if (UtilitarioGeral.validaCodigo(definicaoTipoDocumentoFinanceiro.codigoPlanoContaCategoriaPadrao)) {
                        computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoPlanoContaCategoria = definicaoTipoDocumentoFinanceiro.codigoPlanoContaCategoriaPadrao;
                        computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].nomePlanoContaCategoria = definicaoTipoDocumentoFinanceiro.nomePlanoContaCategoriaPadrao;
                      }

                      if (UtilitarioGeral.validaBoleano(definicaoTipoDocumentoFinanceiro.gerarCarteira)) {
                        computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].gerarCarteira = definicaoTipoDocumentoFinanceiro.gerarCarteira;
                      }
                    }
                  }
                }
              },
            });
          }
        }
      }
    }

    async function verificaPreenchimentoDefinicaoConta(index: number) {
      if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoConta)) {
        const pagamentosSemPreencherConta = computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.some((c) => c.codigoConta === undefined || c.codigoConta === 0);
        if (pagamentosSemPreencherConta) {
          Modal.confirm({
            title: 'Confirmação!',
            content: 'Deseja aplicar essa conta para os demais pagamentos não preenchidos?',
            okText: 'Sim',
            okType: 'primary',
            cancelText: 'Não',
            autoFocusButton: null,
            centered: true,
            onOk: async () => {
              for (let indexLinha = 0; indexLinha < computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.length; indexLinha += 1) {
                if (indexLinha !== index) {
                  if (!UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoTituloFinanceiro)
                    && !UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoConta)) {
                    computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoConta = computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoConta;
                  }
                }
              }
            },
          });
        }
      }
    }

    async function verificaPreenchimentoDefinicaoCategoria(index: number) {
      if (UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria)) {
        const pagamentosSemPreenchercategoria = computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.some((c) => c.codigoPlanoContaCategoria === undefined || c.codigoPlanoContaCategoria === 0);
        if (pagamentosSemPreenchercategoria) {
          Modal.confirm({
            title: 'Confirmação!',
            content: 'Deseja aplicar essa categoria para os demais pagamentos não preenchidos?',
            okText: 'Sim',
            okType: 'primary',
            cancelText: 'Não',
            autoFocusButton: null,
            centered: true,
            onOk: async () => {
              for (let indexLinha = 0; indexLinha < computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento.length; indexLinha += 1) {
                if (indexLinha !== index) {
                  if (!UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoTituloFinanceiro)
                    && !UtilitarioGeral.validaCodigo(computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoPlanoContaCategoria)) {
                    computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[indexLinha].codigoPlanoContaCategoria = computedRecebimentoMercadoria.value.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria;
                  }
                }
              }
            },
          });
        }
      }
    }

    function montaTituloPagamento(index:number, detalhePagamento:INotaFiscalDetalhePagamento) {
      let tituloPagamento = `${index + 1}º Pagamento`;
      if (UtilitarioGeral.validaCodigo(detalhePagamento.codigoTituloFinanceiro)) {
        if (detalhePagamento.statusTituloFinanceiro === EStatusTituloFinanceiro.Baixado) {
          tituloPagamento += ' - Título Antecipado';
        } else {
          tituloPagamento += ' - Título Vínculado';
        }
      }
      return tituloPagamento;
    }

    return {
      props,
      ETipoRelacaoPessoa,
      ETipoMovimentoComercial,
      EStatusTituloFinanceiro,
      computedRecebimentoMercadoria,
      state,
      UtilitarioGeral,
      UtilitarioMascara,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      elementoAbaixo,
      elementoAcima,
      verificaElementoEsquerda,
      verificaElementoDireita,
      verificaElementoAcima,
      verificaElementoAbaixo,
      estrategiaAlteracaoPessoa,
      quantidadeFoco,
      quantidadeBlur,
      salvarItemGrade,
      adicionarPagamento,
      excluirPagamento,
      removerTodosPagamentos,
      apresentarBuscarVincularTitulos,
      atualizarFormaPagamento,
      verificaPreenchimentoDefinicaoTipoDocumento,
      verificaPreenchimentoDefinicaoConta,
      verificaPreenchimentoDefinicaoCategoria,
      montaTituloPagamento,
    };
  },
});
